import React from "react";
import "./FollowUs.css";
import IMAGES from "../../img/image";
import { Link } from "react-router-dom";

const FollowUs = () => {
  return (
    <div className="followus-container">
      <span>
        <small>Customer Service</small>
      </span>
      <h2>Reach Us At</h2>
      <div className="socials">
        <Link
          target="_blank"
          to="https://www.facebook.com/profile.php?id=100078106243710"
        >
          <img src={IMAGES.facebook} alt="" />
        </Link>

        <Link target="_blank" to="https://www.instagram.com/clovershop.in/">
          <img src={IMAGES.instagram} alt="" />
        </Link>

        <Link target="_blank" to="https://wa.me/917005447895">
          <img src={IMAGES.whatsapp} alt="" />
        </Link>

        <Link target="_blank" to="https://t.me/clovershopindia">
          <img src={IMAGES.telegram} alt="" />
        </Link>

        <Link target="_blank" to="mailto:clovercustomercare@gmail.com">
          <img src={IMAGES.gmail} alt="" />
        </Link>
      </div>
    </div>
  );
};

export default FollowUs;
