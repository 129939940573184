import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import getUserData from "../../utils/userDataService";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import { message } from "antd";

const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure to Logout?");
    if (confirmLogout) {
      localStorage.clear("token");
      message.success("Logout Successful");
      navigate("/login");
      dispatch(setUser(null));
    }
  };

  return (
    <div className="dashboard-container">
      {location?.pathname !== "/wallet" && (
        <div className="dashboard-menu">
          <h4 className="text-white">Menu</h4>
          <ul>
            <li
              className={`${
                location.pathname === "/user-dashboard" && "active"
              }`}
            >
              <Link to="/user-dashboard">Dashboard</Link>
            </li>
            <li
              className={`${location.pathname === "/my-account" && "active"}`}
            >
              <Link to="/my-account">Account</Link>
            </li>
            <li className={`${location.pathname === "/" && "active"}`}>
              <Link to="/">Recharge</Link>
            </li>
            <li
              className="text-white"
              style={{ cursor: "pointer" }}
              onClick={handleLogout}
            >
              <LogoutIcon className="me-2" />
              Logout
            </li>
          </ul>
        </div>
      )}
      <div className="dashboard-content">{children}</div>
    </div>
  );
};

export default DashboardLayout;
