import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import PaymentIcon from "@mui/icons-material/Payment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import InventoryIcon from "@mui/icons-material/Inventory";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CollectionsIcon from "@mui/icons-material/Collections";
import { Link } from "react-router-dom";
import "./AdminSidebar.css";

const AdminSidebar = () => {
  return (
    <div className="admin-sidebar-container">
      <span>
        <small>MAIN</small>
      </span>
      <ul>
        <li>
          <Link to="/admin-dashboard">
            <HomeIcon className="me-2 icon" />
            Dashboard
          </Link>
        </li>
      </ul>
      <span>
        <small>LISTS</small>
      </span>
      <ul>
        <li>
          <Link to="/admin-orders">
            <ReceiptIcon className="me-2 icon" />
            Orders
          </Link>
        </li>
        <li>
          <Link to="/admin-products">
            <InventoryIcon className="me-2 icon" />
            Products
          </Link>
        </li>
        <li>
          <Link to="/admin-users">
            <GroupIcon className="me-2 icon" />
            Customers
          </Link>
        </li>
        <li>
          <Link to="/admin-payments">
            <PaymentIcon className="me-2 icon" />
            Payments
          </Link>
        </li>
        <li>
          <Link to="/admin-gallery">
            <CollectionsIcon className="me-2 icon" />
            Gallery
          </Link>
        </li>
        <li>
          <Link to="/admin-event">
            <HourglassBottomIcon className="me-2 icon" />
            Event
          </Link>
        </li>
        <li>
          <Link to="/admin-banners">
            <ViewCarouselIcon className="me-2 icon" />
            Banners
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AdminSidebar;
