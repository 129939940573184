import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import EmailIcon from "@mui/icons-material/Email";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LockIcon from "@mui/icons-material/Lock";

import "./Register.css";

const Login = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      form.email === "cloverstore69@gmail.com" ||
      form?.email === "aashirdigital@gmail.com"
    ) {
      try {
        const res = await axios.post("/api/user/admin", form);
        if (res.data.success) {
          localStorage.setItem("token", res.data.token);
          navigate("/admin-dashboard");
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await axios.post("/api/user/login", form);
        if (res.data.success) {
          localStorage.setItem("token", res.data.token);
          navigate("/");
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
        message.error("Something went wrong");
      }
    }
  };

  return (
    <Layout>
      <div className="container-fluid hero-container register-container">
        <div className="row text-center">
          <div className="d-block m-auto col-12 col-sm-12 col-md-6 col-lg-6">
            <form className="register-form" onSubmit={handleSubmit}>
              <h4>Login!</h4>
              <span>Login with the account you have registered.</span>
              <div className="form-fields mb-3">
                <label className="text-start d-block">Email address</label>
                <input
                  onChange={handleChange}
                  value={form?.email}
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email address"
                />
                <EmailIcon className="icon" />
              </div>
              <div className="form-fields mb-3">
                <label className="text-start d-block">Password</label>
                <input
                  onChange={handleChange}
                  value={form?.password}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                />
                <RemoveRedEyeIcon
                  className="icon eyeicon"
                  onClick={togglePasswordVisibility}
                  style={{ cursor: "pointer" }}
                />
                <LockIcon className="icon" />
              </div>
              <button className="register-btn">Login</button>
              <div className="forgot-pass d-flex justify-content-between">
                <h6 className="text-center my-2">
                  New Customer?{" "}
                  <Link to="/register" style={{ color: "var(--t)" }}>
                    Sign Up
                  </Link>
                </h6>
                <h6 className="text-center my-2">
                  <Link to="/forgot-password" style={{ color: "var(--t)" }}>
                    Forgot Password?
                  </Link>
                </h6>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
