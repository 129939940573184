import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/features/userSlice.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/Home/HeroSection";
import UpcomingEvent from "../components/Home/UpcomingEvent.js";
import Games from "../components/Games";
import CancelIcon from "@mui/icons-material/Cancel";
import getUserData from "../utils/userDataService.js";
import "./Home.css";

const Home = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [link, setLink] = useState("");
  const [display, setDisplay] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    getUserData(dispatch, setUser, null);
  }, []);

  // async function getNoti() {
  //   try {
  //     const res = await axios.get("/api/noti/get-noti");
  //     if (res.data.success) {
  //       setImage(res.data.data[0].image);
  //       setLink(res.data.data[0].link);
  //       setDisplay(res.data.data[0].display);
  //     } else {
  //       message.error(res.data.message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  useEffect(() => {
    getUserData();
    // getNoti();
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowPopup(true);
  //   }, 5000);
  // }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want quit?";
      localStorage.setItem("giveaway", "true");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
    localStorage.setItem("giveaway", "false");
  };

  return (
    <Layout>
      <div className="homecontainer" style={{ background: "var(--bg)" }}>
        {showPopup && display === "yes" && (
          <div className="popup-container">
            <div>
              <CancelIcon onClick={handleClosePopup} className="icon" />
            </div>
            <Link target="_blank" to={link}>
              <img src={image} alt="popup-img" />
            </Link>
          </div>
        )}
        <HeroSection />
        <UpcomingEvent />
        <Games title={"Popular Games Top-Up"} />
      </div>
    </Layout>
  );
};

export default Home;
